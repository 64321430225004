
<template>
  <div id="table-demo">
    <div id="payment-table-loading" class="vs-con-loading__container">
      <vx-card  class="">
        <vs-table :data="pageData.data" class="mb-4">
          <template slot="header">
            <div class="md:flex py-2 md:py-4 w-full">
              <div class="sm:flex flex-grow items-center mb-2 md:mb-0">
                <div class="sm:mr-2 mr-0 mb-2 sm:mb-0">
                  <vs-dropdown vs-trigger-click class="cursor-pointer">
                    <div class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                      <span v-if="pageData.total" class="mr-2"> {{paginationInfo }}</span>
                      <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                    </div>
                    <vs-dropdown-menu>
                    <vs-dropdown-item @click="itemsPerPage = 10">
                        <span>10</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item @click="itemsPerPage = 50">
                        <span>50</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item @click="itemsPerPage = 100">
                        <span>100</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item @click="itemsPerPage = 200">
                        <span>200</span>
                    </vs-dropdown-item>
                    </vs-dropdown-menu>
                  </vs-dropdown>
                </div>
              </div>
                <vs-input class="sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4" 
              v-model="searchQuery" @input="updateSearchQuery" placeholder="Search..." />
            </div>
          </template>
          <template slot="thead">
            <vs-th>PaymentID</vs-th>
            <vs-th>Payvia</vs-th>
            <vs-th>Amount</vs-th>
            <vs-th>Date</vs-th>
          </template>

          <template slot-scope="{data}">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td>
                {{tr.paymentid}}
              </vs-td>
              <vs-td>
                {{tr.payvia}}
              </vs-td>
              <vs-td>
                <span class="uppercase">{{tr.currency}}</span>{{tr.paid_amount | k_formatter}}
              </vs-td>
              <vs-td>
                {{tr.created_at | date_ago}}
              </vs-td>

              <template slot="expand">
                <div class="con-expand-users expand-users w-full p-3">
                  <vs-list>
                    <vs-list-item  title="Method of Payment" :subtitle="tr.method"></vs-list-item>
                    <vs-list-item title="Transaction Ref" :subtitle="tr.transref"></vs-list-item>
                    <vs-list-item title="Currency" :subtitle="tr.currency"></vs-list-item>
                    <vs-list-item title="Description" :subtitle="tr.desc"></vs-list-item>
                    <vs-list-item title="User Email" :subtitle="tr.user_email"></vs-list-item>
                  </vs-list>
                </div>
              </template>
            </vs-tr>
          </template>
        </vs-table>
        <vs-pagination
          :total="pageData.last_page || 1"
          :max="7"
          v-model="currentPage" />
      </vx-card>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';

export default{
  data() {
    return {
      pageData: {
        data:[]
      },
      searchQuery: "",
    }
  },
  computed:{
    currentPage: {
      get() {
        return this.pageData.current_page
      },
      set(val) {
        if(!this.pageData.current_page || this.pageData.current_page == val) return
        this.fetchPayments(val)
      }
    },
    itemsPerPage: {
      get() {
        return +this.pageData.per_page || 10
      },
      set(val) {
        this.fetchPayments(1, val)
      }
    },
    paginationInfo(){
      return `${this.currentPage * this.itemsPerPage - (this.itemsPerPage - 1)} 
      - ${this.pageData.total - this.currentPage * this.itemsPerPage > 0 
      ? this.currentPage * this.itemsPerPage : this.pageData.total}
       of ${this.pageData.total}`
    }
  },
  methods: {
    fetchPayments(page, per_page = null) {
      let payload = {page: page || 1, per_page: per_page || this.itemsPerPage }
      if(this.searchQuery){ payload.search = this.searchQuery }
      this.$vs.loading({ container: '#payment-table-loading', color: "#444", type: "sound"})
      this.$http.get(`/payments?${new URLSearchParams(payload).toString()}`)
      .then((response) => {
        this.$vs.loading.close('#payment-table-loading > .con-vs-loading')
        if(response.data.success){
          this.pageData = response.data.data;
        }
      })
    },
    updateSearchQuery: _.debounce(function(string) {
      this.fetchPayments()
    }, 2000),
  },
  mounted(){
    this.fetchPayments()
  },
}
</script>

<style type="text/css">
[dir] .vx-card .vx-card__collapsible-content .vx-card__body {
    padding-top: .5rem !important;
}
</style>
